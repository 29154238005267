/**
 * step 1!
 * define the url in which we should look for widgets
 */
let srcBaseUrl;
// create a loader for all secutix dependencies
const scriptTags = document.getElementsByTagName('script');
const leni = scriptTags.length;

/**
 * To ensure that there is no wrong cache, we use the manifest.json file to have a different name of the js file each release
 * In the Widget loader function, we have first to get the mapping and then apply the name accordingly
 */
let sourceMap;

// use a loop because of htmlCollections
for (let i = 0; i < leni; i += 1) {
  const scriptTag = scriptTags[i];
  const src = scriptTag.src;
  // take the source only if it
  if (src && src.indexOf('Widgets.js') !== -1) {
    const srcParts = src.split('/');
    srcParts.pop();
    srcBaseUrl = [...srcParts, ''].join('/');
    break;
  }
}
/**
 * Step 2!
 * Ensure the manifest and the vendor have been loaded
 */

// Load the sourceMap and return a promise
const loadSourceMap = () =>
  new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.overrideMimeType('application/json');
    // when getting the manifest append a timestamp to ensure that there is no cache (each time the request is different, so the file is not yet in cache)
    xhr.open('get', `${srcBaseUrl}manifest.json?ts=${Date.now()}`, true);
    xhr.onload = () => {
      const successCode = 200;
      const redirectionCode = 300
      if (xhr.status >= successCode && xhr.status < redirectionCode) {
        sourceMap = JSON.parse(xhr.response);
        resolve(xhr.response);
      } else {
        resolve();
      }
    };
    xhr.onerror = () => {
      resolve();
    };
    xhr.send();
  });

// define how to load a source
// create a tagName to load the source. Use a promise to inform when to do the callback

const loadSource = widgetName => new Promise((resolve) => {
    const newScriptTag = document.createElement('script');
    // create the filename with extension
    const widgetFileName = `${widgetName}.js`;
    // Restrict to use widget files from source map
    // const source = sourceMap && sourceMap[widgetFileName] || widgetFileName; // For dev only
    const source = sourceMap && sourceMap[widgetFileName];
    newScriptTag.src = `${srcBaseUrl}${source}`;
    newScriptTag.onload = () => resolve(srcBaseUrl);
    newScriptTag.onreadystatechange = () => resolve(srcBaseUrl);
    // get the domElement in which we'll load the sources
    const domEl = document.getElementById('Secutix') || document.body;
    domEl.appendChild(newScriptTag);
  });

let vendorPromise;

/**
 * Step 3!
 * Load the needed widgets widgets
 */

// keep the promise of widget loading
const loadingWidgets = {};

// assign the promise
const loadWidgetSource = widgetName => {
  // get the vendor promises because it must be loaded first
  vendorPromise = vendorPromise || loadSourceMap().then(() => loadSource('vendor'));
  loadingWidgets[widgetName] = vendorPromise.then(() => loadSource(widgetName));
};

export const loadWidgets = (widgetNames, onLoad) => {
  // get the list of not loaded/loading widgets
  const widgetsToLoad = widgetNames.filter(widgetName => !loadingWidgets[widgetName]);
  // add the promises on the load
  widgetsToLoad.map(widgetName => loadWidgetSource(widgetName));
  // get the promises of loading or loaded files
  const loadingPromises = widgetNames.map(widgetName => loadingWidgets[widgetName]);
  // when all promises have been resolved -> call the onLoad function
  Promise.all(loadingPromises).then(onLoad);
};

export const getWidgetBaseUrl = () => srcBaseUrl;
